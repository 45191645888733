
import React, { useState } from 'react';
import { Form, TextArea, Button, Icon } from 'semantic-ui-react';
import axios from 'axios';
import s from "./style.module.css";
import { MdContentCopy } from 'react-icons/md';

export default function Translator() {
    const [inputText, setInputText] = useState('');
    const [resultText, setResultText] = useState('');
    const API_KEY_TRANSLATOR = process.env.REACT_APP_API_KEY_TRANSLATOR;

    const detectLanguage = async (text) => {
        try {
            const res = await fetch("https://libretranslate.com/detect", {
                method: "POST",
                body: JSON.stringify({ 
                    q: text,                    
                    api_key: API_KEY_TRANSLATOR
                 }),
                headers: { "Content-Type": "application/json" }
            });

            if (!res.ok) {
                throw new Error("Erreur lors de la détection de la langue.");
            }

            const data = await res.json();
            return data[0].language; // Retourne la langue détectée la plus probable
        } catch (error) {
            console.error("Erreur lors de la détection de la langue :", error);
            return null;
        }
    };

    const translateText = async () => {
        try {
            const detectedLang = await detectLanguage(inputText);
            let sourceLang, targetLang;

            if (detectedLang === 'en') {
                sourceLang = 'en';
                targetLang = 'fr';
            } else if (detectedLang === 'fr') {
                sourceLang = 'fr';
                targetLang = 'en';
            } else {
                throw new Error("Langue non prise en charge pour la traduction.");
            }

            const res = await fetch("https://libretranslate.com/translate", {
                method: "POST",
                body: JSON.stringify({
                    q: inputText,
                    source: sourceLang,
                    target: targetLang,
                    format: "text",
                    api_key: API_KEY_TRANSLATOR
                }),
                headers: { "Content-Type": "application/json" }
            });

            if (!res.ok) {
                throw new Error("Erreur lors de la requête de traduction.");
            }

            const data = await res.json();
            setResultText(data.translatedText);
        } catch (error) {
            console.error("Erreur lors de la traduction :", error);
        }
    };

    const copyToClipboard = () => {
        navigator.clipboard.writeText(resultText);
    };

    return (
        <div className={s.container}>
            <div className="app-header">
                <h2 className="header">Traducteur</h2>
            </div>

            <div className='app-body'>
                <div>
                    <Form>
                        <div className={s.inputContainer}>
                            <Form.Field
                                control={TextArea}
                                placeholder='Écris le texte à traduire...'
                                onChange={(e) => setInputText(e.target.value)}
                                style={{ width: '250px' }}
                            />
                        </div>

                        <div className={s.inputContainer}>
                            <Form.Field
                                control={TextArea}
                                placeholder='La traduction est...'
                                value={resultText}
                                readOnly // Empêche la modification du contenu
                                style={{ width: '225px' }}
                            />
                            <Button
                                icon={<MdContentCopy />}
                                onClick={copyToClipboard}
                                style={{ width: '25px' }}
                                className={s.copyBtn}
                            />
                        </div>

                        <Button className='btn' onClick={translateText}>
                            <Icon name='translate' />
                            Traduire
                        </Button>
                    </Form>
                </div>
            </div>
        </div>
    );
}
