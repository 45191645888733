import React, { useState } from 'react';
import { Form, TextArea, Button, Icon } from 'semantic-ui-react';
import axios from 'axios';
import s from "./style.module.css";
import Translator from '../Translator/Translator'; // Importez le composant Translator

export default function TranslatorContainer({isTranslatorVisible, setIsTranslatorVisible}) {
    console.log(isTranslatorVisible)
    // Fonction pour afficher le composant Translator
    const showTranslator = () => {
        setIsTranslatorVisible(true);
    };

    return (
        <div className={s.container}>
            <div>
                {!isTranslatorVisible ? ( // Vérifiez si le composant Translator doit être affiché
                    <div className={s.btnContainer}>
                        <button className='btn' onClick={showTranslator}>
                            Utiliser le traducteur
                        </button>
                    </div>
                ) : (
                    <Translator/> // Affichez le composant Translator si isTranslatorVisible est vrai
                )}
            </div>
        </div>
    );
}
